@use "@/assets/styles/global" as global;

.container {
  width: 100%;
  height: 100%;
  padding: 50px 70px 50px;
  border-bottom: 1px solid global.$grey200;

  &__button {
    width: 100%;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
    }
  }

  &__title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 700;
    color: global.$grey800;
  }
}

@include global.tablet {
  .container {
    padding: 30px 50px 30px;

    &__title {
      font-size: 24px;
      line-height: 34px;
    }
  }
}

@include global.mobile {
  .container {
    padding: 20px 0px;

    &__title {
      font-size: 20px;
      line-height: 32px;
    }
  }
}
