@use "@/assets/styles/global" as global;

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 120px;
  margin-bottom: 40px;
  line-height: 24px;

  div {
    width: 1180px;
    padding: 0 20px;
  }

  button {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    width: 200px;
    height: 56px;
    padding: 16px 12px 16px 20px;
    border: 1px solid;
    gap: 18px;
    svg {
      padding-top: 2px;
      path {
        fill: none;
        stroke-width: 1.4px;
      }
    }
  }
}

@include global.mobile {
  .container {
    div {
      width: 100%;
    }
  }
}
