@use "@/assets/styles/global" as global;

.container {
  display: flex;
  flex-direction: column;
  width: 415px;
  height: 680px;
  background-color: global.$white;
  position: relative;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;

  &:nth-child(2n-1) {
    top: 140px;
  }

  &__image {
    width: 100%;
    margin-bottom: 40px;
    position: relative;

    img {
      background-color: global.$grey600;
      width: 375px;
      height: 375px;
      object-fit: contain;
    }
  }

  &__contents {
    display: flex;
    flex-direction: column;

    padding: 0 10px;

    h3 {
      font-size: 32px;
      line-height: 44px;
      color: global.$grey800;
      margin-bottom: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
    }

    p {
      span {
        font-size: 20px;
        line-height: 32px;
      }
    }

    &__description {
      margin-bottom: 30px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: pre-wrap;
      span {
        color: global.$grey600;
      }
    }

    &__date {
      span {
        color: global.$grey400;
      }
    }
  }
}

@include global.content-tablet {
  .container {
    width: 255px;
    height: 418px;
    padding: 15px 15px 0px 15px;

    &:nth-child(2n-1) {
      top: 80px;
    }

    &__image {
      margin-bottom: 25px;

      img {
        width: 225px;
        height: 225px;
      }
    }

    &__contents {
      padding: 0 10px;

      h3 {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }

      p {
        span {
          font-size: 16px;
          line-height: 24px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          white-space: pre-wrap;
        }
      }

      &__description {
        margin-bottom: 10px;
      }
    }
  }
}

@include global.mobile {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: global.$white;
    position: static;
    border-radius: 2vw;
    padding: 4vw 4vw 8vw 4vw;
    cursor: pointer;

    &__image {
      margin-bottom: 2vh;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__contents {
      padding: 0 2vw;

      h3 {
        font-size: 5.5vw;
        line-height: 8vw;
        margin-bottom: 1.5vw;
      }

      p {
        span {
          font-size: 4vw;
          line-height: 6.5vw;
        }
      }

      &__description {
        margin-bottom: 2vw;
      }

      &__date {
        margin-bottom: 5vw;
        span {
          color: global.$grey400;
        }
      }
    }
  }
}

// @include global.mobile {
//   .container {
//     display: flex;
//     flex-direction: column;
//     // width: 100%;
//     width: 246px;
//     height: 403px;
//     background-color: global.$white;
//     position: static;
//     border-radius: 8px;
//     padding: 15px 15px 0 15px;
//     cursor: pointer;

//     &__image {
//       margin-bottom: 24px;

//       img {
//         width: 100%;
//         height: 100%;
//       }
//     }

//     &__contents {
//       padding: 0 10px;

//       h3 {
//         font-size: 20px;
//         line-height: 32px;
//         margin-bottom: 5px;
//       }

//       p {
//         span {
//           font-size: 14px;
//           line-height: 20px;
//         }
//       }

//       &__description {
//         margin-bottom: 10px;
//         text-overflow: ellipsis;
//         overflow: hidden;
//         display: -webkit-box;
//         -webkit-box-orient: vertical;
//         -webkit-line-clamp: 1;
//         white-space: pre-wrap;
//       }

//       &__date {
//         margin-bottom: 30px;
//         span {
//           color: global.$grey400;
//         }
//       }
//     }
//   }
// }
