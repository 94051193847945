@use "@/assets/styles/global" as global;

.container {
  background-size: contain;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center center;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 20px;
  bottom: 20px;

  p {
    span {
      color: global.$white;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

@include global.tablet {
  .container {
    left: 12px;
    bottom: 12px;

    p {
      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

@include global.mobile {
  .container {
    left: 10px;
    bottom: 10px;

    p {
      span {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
