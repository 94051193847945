@use "@/assets/styles/global" as global;

.button_container {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  padding: 14px 32px;
  border-radius: 50px;

  &.default {
    color: global.$primary900;
    font-weight: bold;
    border: 1px solid global.$white;
    background-color: global.$white;
  }
}
