@use "@/assets/styles/global" as global;

.container {
  position: fixed;
  right: 50px;
  bottom: 24px;
  width: 135px;
  height: auto;
  cursor: pointer;
  -webkit-animation: bounce 2s infinite ease-in-out;
  -o-animation: bounce 2s infinite ease-in-out;
  -ms-animation: bounce 2s infinite ease-in-out;
  -moz-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;

  img {
    width: 100%;
    height: 100%;
  }
}

@include global.mobile {
  .container {
    right: 6px;
    bottom: 6px;
    width: 80px;
  }
}
