@use "@/assets/styles/global" as global;

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  div {
    width: 100%;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  p {
    span {
      font-size: 20px;
      line-height: 32px;
      color: global.$grey600;
    }
  }
}

@include global.tablet {
  .container {
    div {
      margin-bottom: 15px;
    }

    h3 {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 5px;
    }

    p {
      span {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
}

@include global.mobile {
  .container {
    div {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
    }

    p {
      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
