@use "@/assets/styles/global" as global;

.container {
  z-index: 999;

  .menu_container {
    width: 32px;
    height: 28px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 999;

    & span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: global.$black;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }

    & span:nth-child(1) {
      top: 0px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    & span:nth-child(2) {
      top: 10px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }

    & span:nth-child(3) {
      top: 20px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  .is_open {
    & span:nth-child(1) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 0px;
      left: 5px;
    }

    & span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    & span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      top: 23px;
      left: 5px;
    }
  }

  .menu__nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: global.$white;
    height: 100vh;
    box-shadow: 0px 0px 20px rgba(18, 38, 153, 0.2);
    padding: 0 28px;

    & .header__nav {
      display: flex;
      flex-direction: column;
      margin-top: 106px;

      a {
        font-size: 26px;
        font-weight: 700;
        padding: 16px 0;
      }

      div {
        display: flex;
        flex-direction: column;

        p {
          font-size: 26px;
          font-weight: 700;
          padding: 16px 0;
        }

        a {
          font-size: 20px !important;
          line-height: 32px !important;
          color: global.$grey700;
          padding: 14px 0 14px 8px;
        }
      }
    }
  }
}

@include global.mobile {
  .container {
    .menu_nav {
      height: 100vh;
    }
  }
}
