@use "@/assets/styles/global" as global;

.container {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: global.$white;
  border-radius: 40px;
}

.secondary {
  background-color: global.$secondary400;
}

.black {
  background-color: global.$grey800;
}

@include global.tablet {
  .container {
    padding: 8px 20px;
    font-size: 20px;
    line-height: 32px;
  }
}

@include global.mobile {
  .container {
    padding: 6px 16px;
    font-size: 16px;
    line-height: 24px;
  }
}
