@use "@/assets/styles/global" as global;

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 10px;
  width: 310px;

  div {
    display: flex;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    span {
      font-size: 20px;
      line-height: 32px;
      color: global.$grey500;
    }
  }
}

@include global.tablet {
  .container {
    gap: 10px;
  }
}

@include global.mobile {
  .container {
    gap: 10px;
    width: unset;

    p {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
