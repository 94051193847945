@use "@/assets/styles/global" as global;

.container {
  font-size: 18px;
  line-height: 30px;
  padding: 14px 38px;
  border-radius: 50px;
  font-weight: 700;
  width: 140px;
}

.default {
  border: 1px solid global.$primary900;
  background-color: global.$primary900;
  color: global.$white;
}

.default-outlined {
  border: 1px solid global.$primary900;
  color: global.$primary900;
}

.white {
  border: 1px solid global.$white;
  background-color: global.$white;
  color: global.$primary900;
}

.white-outlined {
  border: 1px solid global.$white;
  background-color: global.$primary900;
  color: global.$white;
}
